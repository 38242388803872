import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import Checkmark from '../components/atoms/Checkmark';
import CFS from '../components/molecules/CFS';

import '../assets/styles/pages/trabaja-con-nosotros.scss';

const TrabajaConNostros = () => (
  <Layout
    title="Trabaja con Nosotros | ELE Barcelona"
    description="Trabajamos cada día para ofrecer los mejores cursos de profesor de español. Si te apasionan los idiomas y te encanta enseñar: ¡Únete a nuestro Equipo!"
  >
    <div className="trabaja-con-nosotros">
      <CFS
        title="¡ELE Barcelona busca formadores!"
        description="Buscamos tutores cualificados para nuestros cursos. La función principal es impartir los cursos de formación de profesores, ya sea a tiempo completo o parcial."
      />
      <section className="second-section c-section-py-80">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="item">
                <h3 className="item__title">Las principales responsabilidades son:</h3>
                <Checkmark>Preparar e impartir sesiones teóricas.</Checkmark>
                <Checkmark>Ayudar al desarrollo continuo del curso.</Checkmark>
                <Checkmark>Dar clases demostrativas</Checkmark>
                <Checkmark>Evaluación de aprendices.</Checkmark>
                <Checkmark>Organización del horario de prácticas docentes.</Checkmark>
                <Checkmark>
                  Asumir la responsabilidad general del buen funcionamiento del curso.
                </Checkmark>
              </div>
            </div>
            <div className="col-md-6">
              <div className="item">
                <h3 className="item__title">Requisitos esenciales:</h3>
                <Checkmark>
                  Mínimo 5 años de experiencia dando clases de español de diferentes áreas.
                </Checkmark>
                <Checkmark>2 años de experiencia en formación de profesores.</Checkmark>
                <Checkmark>Pasión por la enseñanza.</Checkmark>
                <Checkmark>Disponibilidad.</Checkmark>
              </div>
            </div>
            <div className="col-md-6">
              <div className="item">
                <h3 className="item__title">Preferencias:</h3>
                <Checkmark>Autónomo/a.</Checkmark>
                <Checkmark>Experiencia previa en coordinación y atención al público.</Checkmark>
                <Checkmark>Conocimientos curriculares docentes ELE.</Checkmark>
              </div>
            </div>
          </div>
          <div className="row cta-block">
            <div className="col">
              <div className="cta">
                <div className="left">
                  <p className="cta__description">
                    Si cumples con los requisitos anteriores y deseas obtener más información, envía
                    tu currículum a{' '}
                    <a href="mailto:maria@elebarcelona.com">
                      <strong>maria@elebarcelona.com</strong>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default TrabajaConNostros;
